import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import Providers from "components/Providers";

import "./styles/index.scss";
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import relativeTime from "dayjs/plugin/relativeTime";
import { Suspense, lazy } from "react";
import { Row, Spin } from "antd";

const App = lazy(() => import("./App"));

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense
    fallback={
      <Row
        style={{ height: "100vh", width: "100vw" }}
        justify="center"
        align="middle"
      >
        <Spin />
      </Row>
    }
  >
    <App />
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
